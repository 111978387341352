<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold py-5">{{ title }}</h4>
    <b-form class="form" v-on:submit.prevent="saveDepartment">
      <!-- begin:Name  -->
      <b-form-group label-for="department-name-input">
        {{ $t("DEPARTMENTFORM.NAME") }}
        <b-form-input
          id="department-name-input"
          class="form-control h-auto"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          :placeholder="$t('DEPARTMENTFORM.NAME1')"
        >
        </b-form-input>
      </b-form-group>
      <!-- end:Name  -->

      <!-- begin:description  -->
      <b-form-group label-for="department-desc-input">
        {{ $t("DEPARTMENTFORM.DESCRIPTION") }}
        <b-form-textarea
          id="department-desc-input"
          class="form-control h-auto"
          :placeholder="$t('DEPARTMENTFORM.DESCRIPTION1')"
          rows="3"
          max-rows="6"
          v-model="$v.form.description.$model"
          :state="validateState('description')"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end:Name  -->

      <!-- begin:date  -->
      <b-form-group label-for="department-created-input">
        {{ $t("DEPARTMENTFORM.DATECREATED") }}
        <b-form-datepicker
          id="department-created-input"
          class="form-control h-auto"
          v-model="$v.form.date.$model"
          :state="validateState('date')"
        >
        </b-form-datepicker>
      </b-form-group>
      <!-- end:date  -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <button
          type="submit"
          ref="department-form-submit"
          class="btn btn-primary font-weight-bolder"
        >
          {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "DepartmentForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Department Form",
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        date: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
      date: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    saveDepartment() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const name = this.$v.form.name.$model;
      const description = this.$v.form.description.$model;
      const date = this.$v.form.date.$model;

      const submitButton = this.$refs["department-form-submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      console.log(name);
      console.log(description);
      console.log(date);

      this.$store
        .dispatch("create", {
          name,
          description,
          from_date: date,
        })
        .then((response) => {
          this.$emit("close", response.data);
        })
        .finally(() =>
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          )
        );
    },
    close(response) {
      return response;
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.CompanyModule.department.form.response,
    }),
  },
};
</script>

<style scoped></style>
